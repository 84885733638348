import React from 'react';
import LogoImage from '../assets/img/starlife_logo_new_white.png'; // Import the logo

function ThemedSuspense() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-[#762F87]">
      {/* Logo centered and resized for better appearance */}
      <img
        src={LogoImage}
        alt="StarLife Logo"
        className="w-52 md:w-64 lg:w-72 animate-grow" // Adjust the width dynamically for different screen sizes
      />
      {/* Responsive Loading text */}
      <p className="text-white mt-8 text-base md:text-lg lg:text-xl animate-grow">Loading...</p>
    </div>
  );
}

export default ThemedSuspense;
