import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';

import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import customTheme from './theme/customTheme';

// Lazy load all context providers and the App component
const App = lazy(() => import('./App'));
const SidebarProvider = lazy(() => import('./context/SidebarContext').then(module => ({ default: module.SidebarProvider })));
const ApplicationContextProvider = lazy(() => import('./context/ApplicationContext').then(module => ({ default: module.ApplicationContextProvider })));
const BenefitsRequestProvider = lazy(() => import('./context/BenefitsRequestContext').then(module => ({ default: module.BenefitsRequestProvider })));
const ClientContextProvider = lazy(() => import('./context/ClientContext').then(module => ({ default: module.ClientContextProvider })));
const AgentContextProvider = lazy(() => import('./context/AgentContext').then(module => ({ default: module.AgentContextProvider })));
const ProposalDetailsProvider = lazy(() => import('./context/ProposalDetailsContext').then(module => ({ default: module.ProposalDetailsProvider })));
const PaymentDataContextProvider = lazy(() => import('./context/PaymentDataContext').then(module => ({ default: module.PaymentDataContextProvider })));
const SignatureContextProvider = lazy(() => import('./context/SignatureContext').then(module => ({ default: module.SignatureContextProvider })));
const TrusteeContextProvider = lazy(() => import('./context/TrusteeContext').then(module => ({ default: module.TrusteeContextProvider })));
const BeneficiaryContextProvider = lazy(() => import('./context/BeneficiaryContext').then(module => ({ default: module.BeneficiaryContextProvider })));
const CheckboxProvider = lazy(() => import('./context/CheckboxContext').then(module => ({ default: module.CheckboxProvider })));
const ProductDetailsContextProvider = lazy(() => import('./context/ProductDetailsContext').then(module => ({ default: module.ProductDetailsContextProvider })));

// Wrapping everything inside a single Suspense with a fallback UI
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<ThemedSuspense />} persistor={persistor}>
      <Suspense fallback={<ThemedSuspense />}>
        <Windmill theme={customTheme}>
          {/* All context providers wrapped inside Suspense */}
          <SidebarProvider>
            <ApplicationContextProvider>
              <BenefitsRequestProvider>
                <ClientContextProvider>
                  <AgentContextProvider>
                    <ProposalDetailsProvider>
                      <PaymentDataContextProvider>
                        <SignatureContextProvider>
                          <TrusteeContextProvider>
                            <BeneficiaryContextProvider>
                              <CheckboxProvider>
                                <ProductDetailsContextProvider>
                                  <App />
                                </ProductDetailsContextProvider>
                              </CheckboxProvider>
                            </BeneficiaryContextProvider>
                          </TrusteeContextProvider>
                        </SignatureContextProvider>
                      </PaymentDataContextProvider>
                    </ProposalDetailsProvider>
                  </AgentContextProvider>
                </ClientContextProvider>
              </BenefitsRequestProvider>
            </ApplicationContextProvider>
          </SidebarProvider>
        </Windmill>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
