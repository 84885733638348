import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  signupToken: null,
  loginError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
      state.loginError = null;
    },
    loginFailure: (state, action) => {
      console.log('Login Failure:', action.payload.error);
      state.loginError = action.payload.error;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.signupToken = null;
      state.isLoggedIn = false;
      state.loginError = null;
    },
    setSignupToken: (state, action) => {
      state.signupToken = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
  },
});

export const { loginSuccess, loginFailure, logout, setSignupToken, updateAccessToken, updateRefreshToken } = authSlice.actions;

export default authSlice.reducer;
