// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  first_name: '',
  last_name: '',
  middle_name: '',
  dob: '',
  email: '',
  phone_number: '',
  address: '',
  id_type: '',
  id_number: '',
  last_login: null,
  user_type: '',
  profile_image: '',
  is_verified: null,
  registration_stage: 0,
  failed_login_attempts: null,
  is_locked: null,
  lockout_until: null,
  last_activity: null,
  userPolicyDetails: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPolicies: (state, action) => {
      state.policies = action.payload;  
    },
     setUserPolicyDetails: (state, action) => {
      state.userPolicyDetails = action.payload;
    },
    updateRegistrationStage: (state, action) => {
      state.registration_stage = action.payload;
    },
    clearUser: () => initialState,
  },
});

export const { setUser, setPolicies, setUserPolicyDetails, updateRegistrationStage, clearUser } = userSlice.actions;

export default userSlice.reducer;
