
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addOrUpdateApplication, getApplication } from '../utils/indexedDB';

// Thunk to fetch a specific application by ID
export const fetchApplication = createAsyncThunk(
  'clientDetails/fetchApplication',
  async (applicationID) => {
    const application = await getApplication(applicationID);
    if (!application) throw new Error('Application not found');
    return application;
  }
);

// Thunk to save progress by updating the application in IndexedDB
export const saveProgress = createAsyncThunk(
  'clientDetails/saveProgress',
  async (updatedData, { getState }) => {
    const { applicationData } = getState().clientDetails;
    const updatedApplication = {
      ...applicationData,
      ...updatedData,
    };
    await addOrUpdateApplication(updatedApplication);
    return updatedApplication;
  }
);

const clientDetailsSlice = createSlice({
  name: 'clientDetails',
  initialState: {
    applicationData: {},
    currentApplicationID: null,
    currentStep: 0, // Add currentStep to state
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentApplicationID: (state, action) => {
      state.currentApplicationID = action.payload;
    },
    updateApplicationData: (state, action) => {
      state.applicationData = {
        ...state.applicationData,
        ...action.payload,
      };
    },
    setCurrentStep: (state, action) => { // Add setCurrentStep reducer
      state.currentStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.applicationData = action.payload;
        state.currentApplicationID = action.payload.id;
      })
      .addCase(fetchApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveProgress.fulfilled, (state, action) => {
        state.applicationData = action.payload;
      });
  },
});

export const { setCurrentApplicationID, updateApplicationData, setCurrentStep } = clientDetailsSlice.actions;
export default clientDetailsSlice.reducer;
