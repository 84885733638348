// stepCompletionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const stepCompletionSlice = createSlice({
    name: 'stepCompletion',
    initialState: {
        currentStep: 0,
        stepCompletion: {},
    },
    reducers: {
        setCurrentStep(state, action) {
            state.currentStep = action.payload;
        },
        setStepCompletion(state, action) {
    const { step, isComplete } = action.payload;
    state.stepCompletion = {
        ...state.stepCompletion,
        [step]: isComplete,
    };
}

    },
});

export const { setCurrentStep, setStepCompletion } = stepCompletionSlice.actions;
export default stepCompletionSlice.reducer;
