// indexedDB.js
const DB_NAME = 'ApplicationsDB';
const DB_VERSION = 1;
const STORE_NAME = 'applications';

export function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(`Database error: ${event.target.errorCode}`);
    };
  });
}

export async function addOrUpdateApplication(application) {
  const db = await openDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);
  store.put(application);
  return transaction.complete;
}

export async function getApplication(id) {
  const db = await openDB();
  const transaction = db.transaction([STORE_NAME]);
  const store = transaction.objectStore(STORE_NAME);
  return store.get(id);
}

export async function getAllApplications() {
  const db = await openDB();
  const transaction = db.transaction([STORE_NAME]);
  const store = transaction.objectStore(STORE_NAME);
  return store.getAll();
}

export async function deleteApplication(id) {
  const db = await openDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);
  store.delete(id);
  return transaction.complete;
}
