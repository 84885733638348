// clientFormDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentRequest: {
    policyNumber: '',
    paymentMethod: '',
    mobileNumber: '',
    totalPremium: '',
  },
  clientInfo: {
    name: '',
    address: '',
    phone: '',
    email: '',
    dob: '',
    idType: '',
    idNumber: '',
  },
  dependents: [],
  productDetails: {
    productName: '',
    productCode: '',
    coverageAmount: '',
  },
  beneficiaryDetails: [],
  trustee: {
    name: '',
    relationship: '',
    contact: '',
  },
  medicalQuestions: {
    hasExistingCondition: false,
    conditions: [],
  },
  paymentDetails: {
    method: '',
    amount: 0,
    date: '',
  },
};

const clientFormDataSlice = createSlice({
  name: 'clientFormData',
  initialState,
  reducers: {
    updatePaymentRequest: (state, action) => {
      state.paymentRequest = { ...state.paymentRequest, ...action.payload };
    },
    updateClientInfo: (state, action) => {
      state.clientInfo = { ...state.clientInfo, ...action.payload };
    },
    updateDependents: (state, action) => {
      state.dependents = action.payload;
    },
    updateProductDetails: (state, action) => {
      state.productDetails = { ...state.productDetails, ...action.payload };
    },
    updateBeneficiaryDetails: (state, action) => {
      state.beneficiaryDetails = action.payload;
    },
    updateTrustee: (state, action) => {
      state.trustee = { ...state.trustee, ...action.payload };
    },
    updateMedicalQuestions: (state, action) => {
      state.medicalQuestions = { ...state.medicalQuestions, ...action.payload };
    },
    updatePaymentDetails: (state, action) => {
      state.paymentDetails = { ...state.paymentDetails, ...action.payload };
    },
    clearFormData: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  updatePaymentRequest,
  updateClientInfo,
  updateDependents,
  updateProductDetails,
  updateBeneficiaryDetails,
  updateTrustee,
  updateMedicalQuestions,
  updatePaymentDetails,
  clearFormData,
} = clientFormDataSlice.actions;

export default clientFormDataSlice.reducer;
