// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import userReducer from './userSlice';
import clientFormDataReducer from './clientFormDataSlice';
import clientDetailsReducer from './clientDetailsSlice';
import stepCompletionReducer from './stepCompletionSlice'; // Import the new slice

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isLoggedIn', 'accessToken', 'refreshToken', 'signupToken'],
};

const userPersistConfig = {
    key: 'user',
    storage,
};

const rootReducer = {
    auth: persistReducer(authPersistConfig, authReducer),
    user: persistReducer(userPersistConfig, userReducer),
    clientFormData: clientFormDataReducer,
    clientDetails: clientDetailsReducer,
    stepCompletion: stepCompletionReducer, // Add the new reducer
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
});

export const persistor = persistStore(store);
